<template>
  <v-container style="margin-bottom: 10vw; margin-top: 50px">
    <v-row justify="center">
      <v-col cols="12" sm="3" style="display: contents">
        <div
          data-aos-duration="1000"
          data-aos="zoom-out-up"
          data-aos-anchor-placement="bottom-bottom"
        >
          <h1
            class="font-weight-bold mb-3 secTitle"
            style="font-size: 60px; width: fit-content"
          >
            {{title[lang]}}
          </h1>
        </div>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col
        cols="12"
        sm="4"
        v-for="(blog, index) in blogs"
        :key="index"
        style="min-height: 400pt; text-align: center"
      >
        <v-card
          style="width: 100%; height: 400px; overflow: hidden"
          data-aos-duration="1000"
          data-aos="fade-up"
          :data-aos-delay="100 * index"
        >
          <img
            :src="origin + blog.photos[0]"
            alt=""
            style="height: 60%; width: 80%"
            @load="oload"
          />
          <h3>
            {{ blog.title[lang] }}
          </h3>
          <div style="text-align:center; padding 10px; ">
            <p style="margin: 15px">
              {{ blog.publishedOn }}
              <br />
              <br />
              {{ blog.description[lang] }}
            </p>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  computed: {
    blogs() {
      return this.$store.state.products;
    },
    origin() {
      return this.$store.state.origin;
    },
    title(){
        return this.$store.state.productsTitle;
    },
    lang() {
      return this.$store.state.lang;
    },
  },
  methods: {
    oload() {
      this.$store.commit("setoverlay", { overlay: false });
    },
  },
  mounted() {
    this.$store.commit("setoverlay", { overlay: false });
  },
};
</script>
